import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    versionId: "",
    versionName: "",
    title: "",
    text: "",
    colors: [],
    selectedColor: {},
    images: [],
    colorLocation: "external",
};

export const getColors = createAsyncThunk(
    "colorSelector/getColors",
    async (versionId) => {
        try {
            const response = await axios.get(
                `/api/vehicles/360_galleries/${versionId}/`
            );
            return response.data.colors;
        } catch (error) {
            console.log(error);
        }
    }
);

export const colorSelectorSlice = createSlice({
    name: "colorSelector",
    initialState,
    reducers: {
        setVersionId(state, action) {
            state.versionId = action.payload;
        },
        setVersionName(state, action) {
            state.versionName = action.payload;
        },
        setTitle(state, action) {
            state.title = action.payload;
        },
        setText(state, action) {
            state.text = action.payload;
        },
        setImages(state, action) {
            state.images = state.selectedColor.fullcirclegallery?.images;
        },
        setSelectedColor(state, action) {
            // state.selectedColor = action.payload
            state.selectedColor = state.colors.find(
                (color) => color.id === action.payload
            );
            // state.images = state.selectedColor.fullcirclegallery.images
        },
        setColorLocation(state, action) {
            state.colorLocation = action.payload;
        },
    },
    extraReducers: {
        [getColors.fulfilled]: (state, action) => {
            state.colors = action.payload;
            state.selectedColor = state.colors.filter(
                (color) => color.color_type === state.colorLocation
            )[0];
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setVersionId,
    setVersionName,
    setTitle,
    setText,
    setImages,
    setSelectedColor,
    setColorLocation,
    setFilteredColors,
} = colorSelectorSlice.actions;

export default colorSelectorSlice.reducer;
